import Order from "@/pages/order/order.vue"
import Save from "@/pages/order/save.vue"
import Detail from "@/pages/order/detail.vue"
import Customer from "@/pages/order/customer.vue"
import Channel from "@/pages/order/channel.vue"
import Login from "@/pages/login/login.vue"
import home from "@/pages/index.vue"
import FollowRecord from "@/pages/order/followrecord.vue"
import { createWebHashHistory, createRouter } from 'vue-router'


const constantRoutes = [{
    path: "/",
    component: home,
    meta: {
      title: "首页"
    }
  },
    {
        path: "/home",
        component: home,
        meta: {
            title: "首页"
        }
    },
  {
    path: "/order",
    component: Order
  },
  {
    path: "/save",
    component: Save
  },
  {
    path: "/detail",
    component: Detail
  },
  {
    path: "/customer",
    component: Customer
  },
  {
    path: "/channel",
    component: Channel
  },
  {
    path: "/login",
    component: Login
  },
    {
        path: "/customerManage",
        name: "customerManage",
        component: () => import("../pages/major/customer")
    },
    {
        path: "/customerCheck",
        name: "customerCheck",
        component: () => import("../pages/major/customer/check")
    },
    {
        path: "/customer/recommend",
        name: "recommend",
        component: () => import("../pages/major/customer/recommend")
    },
    {
        path: "/customer/seas",
        name: "customer-seas",
        component: () => import("../pages/major/customer/seas")
    },
    {
        path: "/customer/clue-seas",
        name: "clue-seas",
        component: () => import("../pages/major/customer/clueseas")
    },
    {
        path: "/customer/clue",
        name: "customer-clue",
        component: () => import("../pages/major/customer/clue")
    },
    {
        path: "/followrecord",
        component: FollowRecord
    },
    {
        path: "/channelIndex",
        name:"channel-index",
        component: () => import("../pages/major/channel/index")
    },
    {
        path: "/productIndex",
        name:"product-index",
        component: () => import("../pages/major/channel/product")
    },
    {
        path: '/ordercalculate',
        component: () => import("../pages/major/ordercalculate")
    },
    {
        path: '/auditlist',
        component: () => import("../pages/major/auditlist")
    },
    {
        path: '/auditlistAdd',
        component: () => import("../pages/major/auditlist/add")
    },

    {
        path: '/auditlist/aloneinquiry-add',
        component: () => import("../pages/major/auditlist/aloneinquiry/add")
    },
    {
        path: '/auditlist/aloneinquiry-edit',
        component: () => import("../pages/major/auditlist/aloneinquiry/edit")
    },
    {
        path: '/auditlist/aloneinquiry-check',
        component: () => import("../pages/major/auditlist/aloneinquiry/check")
    },

    {
        path: '/auditlist/billweight-add',
        component: () => import("../pages/major/auditlist/billweight/add")
    },
    {
        path: '/auditlist/billweight-edit',
        component: () => import("../pages/major/auditlist/billweight/edit")
    },
    {
        path: '/auditlist/billweight-check',
        component: () => import("../pages/major/auditlist/billweight/check")
    },

    {
        path: '/auditlist/price-add',
        component: () => import("../pages/major/auditlist/price/add")
    },
    {
        path: '/auditlist/price-edit',
        component: () => import("../pages/major/auditlist/price/edit")
    },
    {
        path: '/auditlist/price-check',
        component: () => import("../pages/major/auditlist/price/check")
    },

    {
        path: '/customer-follower',
        component: () => import("../pages/major/customer/follower")
    },
    {
        path: '/follower-Record',
        component: () => import("../pages/major/followerRecord")
    },
    {
      path: '/predictionsummary',
      component: () => import("../pages/major/predictionsummary")
    },
    {
      path: '/salesorderachieve',
      component: () => import("../pages/major/salesorderachieve")
    },
    {
      path: '/reportpredictionsales',
      component: () => import("../pages/major/predictionsales")
    },
    {
      path: '/predictiondetail',
      component: () => import("../pages/major/predictiondetail")
    },
    {
      path: '/predictionarrive',
      component: () => import("../pages/major/predictionarrive")
    },
    {
      path: '/dragontger',
      component: () => import("../pages/major/dragontger")
    },
    {
        path: '/kpi',
        component: () => import("../pages/major/kpi")
    },
    {
        path: '/kpi/kpi-check',
        component: () => import("../pages/major/kpi/check")
    },
    {
        path: '/kpi/kpi-score',
        component: () => import("../pages/major/kpi/score")
    },
    {
        path: '/container',
        component: () => import("../pages/major/container"),
        meta: {keepAlive: true}
    },
    {
        path: '/container/container-edit',
        component: () => import("../pages/major/container/edit"),
    },
    {
        path: '/housing',
        component: () => import("../pages/major/housing"),
        meta: {keepAlive: true}
    },
    {
        path: '/housing/housing-edit',
        component: () => import("../pages/major/housing/edit"),
    },
    {
        path: '/housing/check',
        component: () => import("../pages/major/housing/check"),
    },
    {
        path: '/approval/launch',
        component: () => import("../pages/major/approval/launch"),
        meta: {keepAlive: true}
    },
    {
        path: '/approval/launch/auditCustomer',
        component: () => import("../pages/major/approval/auditCustomer"),
    },
    {
        path: '/market/orderLine',
        component: () => import("../pages/major/market/orderLine"),
    },
    {
        path: '/market/order',
        component: () => import("../pages/major/market/order"),
    },
    {
        path: '/market/orderZG',
        component: () => import("../pages/major/market/orderZG"),
    },
    {
        path: '/market/orderLDP',
        component: () => import("../pages/major/market/orderLDP"),
    },
    {
        path: '/market/customs/index',
        component: () => import("../pages/major/market/customs/index"),
    },
    {
        path: '/market/customs/add',
        component: () => import("../pages/major/market/customs/add"),
    },
    {
        path: '/market/customs/reply',
        component: () => import("../pages/major/market/customs/reply"),
    },
    {
        title: '扫码页面',
        name: 'scanCodePage',
        path: '/scanCodePage',
        component: () => import('../pages/major/market/scanCodePage')
    },
    {
        path: '/market/warehouse/goodsconfig',
        component: () => import("../pages/major/market/warehouse/goodsconfig"),
    },
    {
        path: '/market/warehouse/goodsrecord',
        component: () => import("../pages/major/market/warehouse/goodsrecord"),
    },
    {
        path: '/customer/follower/out',
        component: () => import("../pages/major/customer/follower/out/index"),
    },
    {
        path: '/customer/follower/out/add',
        component: () => import("../pages/major/customer/follower/out/add"),
    },
    {
        path: '/customer/follower/out/edit',
        component: () => import("../pages/major/customer/follower/out/edit"),
    },
    {
        path: '/customer/follower/out/view',
        component: () => import("../pages/major/customer/follower/out/view"),
    },
    {
        path: '/customer/follower/out/view',
        component: () => import("../pages/major/customer/follower/out/view"),
    },
    
  // {
  //   path: '/user/:id',
  //   component: User,
  //   children: [
  //     // 当 /user/:id 匹配成功，
  //     // UserHome 会被渲染在 User 的 <router-view> 中
  //     {
  //       path: '',
  //       component: UserHome
  //     }

  //     // ...其他子路由
  //   ]
  // },
]

// const router = createRouter({
//     routes: constantRoutes,
//     scrollBehavior(to, from, savedPosition) {
//         console.dir(to);
//         if (savedPosition) {
//             return savedPosition
//         } else {
//             return { top: 0 }
//         }
//     },
// });

export default constantRoutes;