const env = 'test' //test(测试环境),production(生产环境), dev 开发环境
let baseUrl = env === 'test' ? 'https://bkapi.hubologistics.com' : 'https://bkapi.hubologistics.com'
let newUrl = env === 'test' ? 'https://cwxapi.hubologistics.com' : 'https://cwxapi.hubologistics.com'
// let newUrl = env === 'test' ? 'https://api.test.erp.hubologistics.com' : 'https://api.test.erp.hubologistics.com'
// api.test.erp.hubologistics.com
let omsUrl = "https://oms.hubologistics.com";
// let omsUrl = "https://api.test.oms.hubologistics.com";
baseUrl = env === 'dev' ? "/" : baseUrl
newUrl = env === 'dev' ? "/" : newUrl

let paths = [
    {"path":"/approval/launch","name":"我的审批"},
    {"path":"/customerManage","name":"客户管理"},
    {"path":"/customer/seas","name":"客户公海"},
    {"path":"/customer/clue-seas","name":"线索公海"},
    {"path":"/customer/clue","name":"我的线索"},
    {"path":"/follower-Record","name":"跟进记录"},
    {"path":"/order","name":"预报列表"},
    {"path":"/save","name":"新增预报"},
    {"path":"/market/orderLine","name":"专线订单"},
    {"path":"/market/order","name":"传统订单"},
    {"path":"/market/orderZG","name":"整票订单"},
    {"path":"/market/orderLDP","name":"LDP订单"},
    {"path":"/market/customs/index","name":"关务询盘"},
    {"path":"/market/customs/add","name":"新建关务"},
    {"path":"/ordercalculate","name":"订单测算管理"},
    {"path":"/auditlist","name":"审批单管理"},
    {"path":"/kpi","name":"绩效考核表"},
    {"path":"/housing","name":"入库单"},
    {"path":"/container","name":"装柜单"},
    {"path":"/predictionsummary","name":"预报渠道"},
    {"path":"/predictiondetail","name":"预报客户"},
    {"path":"/reportpredictionsales","name":"预报营销"},
    {"path":"/predictionarrive","name":"客户到仓"},
    {"path":"/dragontger","name":"龙虎榜"},
    {"path":"/salesorderachieve","name":"业务货量"},
    {"path":"/market/warehouse/goodsconfig","name":"关联配置"},
    {"path":"/market/warehouse/goodsrecord","name":"关联记录"},
    {"path":"/customer/follower/out","name":"外出跟进记录"},
    {"path":"/housing/check","name":"入库查验"},
    {"path":"/customer/recommend","name":"业务推荐"},
]

export {
  baseUrl,
  newUrl,
    omsUrl,
    paths
}